<template>
  <div class="buybox-loggedout">
    <h5
      v-text="getProductText"
    />
    <div class="newpdp-popup-wrapper">
      <!-- brand is missing -->
      <ProductDetails
        class="pdp-box"
        :product="buyboxProduct"
        :download-files="productDownloads"
        :product-name="productDescription"
      />
      <!-- :loading currently always false, till warehouse switch is implemented and the component can trigger reload -->
      <AvailabilityPricing
        :loading="false"
      />
    </div>
  </div>
</template>

<script>
import ProductDetails from '~/components/product-detail/bb_components/ProductDetails'
import AvailabilityPricing from '~/components/product-detail/bb_components/AvailabilityPricing'
import {getEComText} from '~/assets/js/utils/product'
import {
  getMappedProduct,
  getMappedProductCategoryImages
} from '~/assets/js/utils/product_attributes_mapping'
import {FILENAME_REGEX, PROPERTY_SCOPE_NAMES} from '~/assets/js/constants'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {storeToRefs} from 'pinia'

export default {
  name: 'ProductBuyBoxPopup',
  components: {
    ProductDetails,
    AvailabilityPricing
  },
  props: {
    isDefaultCustomer: {
      type: Boolean,
      default() {
        return false
      }
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      localProduct: null
    }
  },
  setup() {
    const {newSearch} = storeToRefs(useConfigurationDataStore())
    return {
      newSearch
    }
  },
  computed: {
    productDescription: function () {
      return this.localProduct?.locales[this.$i18n.locale] ?? ''
    },
    buyboxProduct() {
      return getMappedProduct(this.localProduct)
    },
    productDownloads() {
      let result = []
      if (this.localProduct?.assets) {
        result = this.localProduct.assets.filter(
          (asset) => asset.languageShortCode === this.$i18n.locale
        )
        let presentAssetTypes = result.map((asset) => asset.assetType)
        result = result.concat(
          this.localProduct.assets.filter(
            (asset) =>
              asset.languageShortCode === this.$i18n.defaultLocale &&
              !presentAssetTypes.includes(asset.assetType)
          )
        )
        result = result.map((asset) => ({
          ...asset,
          // alt text is to be set to the filename to identify materials in the download section, the filename is extracted from the lookupKey (#28924)
          altText:
            asset.label ||
            (asset.lookupKey &&
            FILENAME_REGEX.test(asset.lookupKey) &&
            asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              ? decodeURI(
                asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              )
              : null),
          sortOrder: this.getSortOrder(asset.assetType)
        }))
      }
      return result.length > 0
        ? result.sort((a, b) => a.sortOrder - b.sortOrder)
        : result
    },
    productWithCategoryDesigns: function() {
      return getMappedProductCategoryImages(this.localProduct, this.categoryWithDesign, this.categoryContents?.Assets.length > 0)
    },
    getProductText() {
      if (this.newSearch) {
        const lang = this.$i18n.locale.toUpperCase()
        const unit = this.showInchValues ? 'Imperial' : 'Metric'
        return this.product['locales_' + lang + '_' + unit + '_SalesTextECom']
      }
      return getEComText(this.$tv, this.localProduct)
    }
  },
  methods: {
    getSortOrder(assetType) {
      if (assetType === 'CAD') {
        return 1
      }
      return 0
    }
  },
  mounted() {
    this.$eventbus.on('emit-warehouse-change', async (alternativePlantCode) => {
      this.localProduct = this.newSearch ?
        await this.getProductBySkuNew(this.localProduct?.sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, alternativePlantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE_NEW) :
        await this.getProductBySku(this.localProduct?.sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, alternativePlantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE)
    })
  },
  watch: {
    product: {
      immediate: true,
      handler: function (val) {
        this.localProduct = val
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
.buybox-loggedout {
  h5 {
    margin: -0.5rem 0 1rem;
  }

  .newpdp-popup-wrapper {
    display: flex;
    gap: 1rem;
  }
}

</style>
